import { Component, OnInit } from '@angular/core';
// import { NgxCarousel } from 'ngx-carousel';
import { GlobalService } from '../../../global.service';
@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.scss']
})

export class LandingpageComponent implements OnInit {

  constructor(public gservice:GlobalService) { }
  slides = [
    {
      "image":"",
      "title1":"EXCELLENT CUSTOMER",
      "title2":"SERVICE",
      "desc1":"We provide excellent customer service.",
      // "desc2":"Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
     {
       "image":"",
       "title1":"PURE WATER",
       "title2":"SYSTEMS",
       "desc1":"As we all know, no one can live without water and this is the first need of any living being.",
       "desc2":"You should understand the importance of water in your life."
       

     },
    
    // {
    //   "image":"",
    //   "title1":"STAND WITH",
    //   "title2":"INNOVATIVE",
    //   "desc1":"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //   "desc2":"Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    // }   
       
  ];
  slideConfig = {"slidesToShow": 5,"slidesToScroll": 1,arrows:true,dots:true,autoplay:false,autoplaySpeed:1000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
        autoplay:true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
  };

  slideConfig2 = {"slidesToShow": 4,"slidesToScroll": 1,arrows:true,dots:true,infinite:true,autoplay:true,autoplaySpeed:1500,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
        autoplay:true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
  };
  slideConfig1 = {
    "slidesToShow": 1,"slidesToScroll": 1,infinite: true,arrows:true,dots:true,autoplay:true,autoplaySpeed:2000,
  };

  
  // addSlide() {
  //   this.slides.push({img: "http://placehold.it/350x150/777777"})
  // }

  // removeSlide() {
  //   this.slides.length = this.slides.length - 1;
  // }

  // afterChange(e) {
  //   console.log('afterChange');
  // }

  
  companySuccees =[
    {
      "image":"../../../../assets/success.png",
      "head1":"10",
      "head2":"YEARS OF",
      "head3":"EXPERIENCE",
    },
    {
      "image":"../../../../assets/money.png",
      "head1":"PRICE",
      "head2":"RELIABLE AND",
      "head3":"AFFORDABLE",
    }
  ]



  slideUp = [
    {
      "title":"DEMINAERLISED WATER",
      "description":"Demineralised water is water that has been purified in such a way that (most of) its mineral- and salt ions are removed. You can think for example of Calcium, Chloride, Sulphate, Magnesium and Sodium. Demineralised water is also known as demi water or deionised water.",
      "image":"../../../../assets/products/1a.jpeg"
    },
    {
      "title":"DISTILLED WATER",
      "description":"Distilled water is water that has been boiled into vapor and condensed back into liquid in a separate container. Impurities in the original water that do not boil below or near the boiling point of water remain in the original container.",
      "image":"../../../../assets/products/2a.jpeg"
    },
    {
      "title":"INDUSTRIAL RO",
      "description":"Reverse osmosis (RO) is a water purification process that uses a partially permeable membrane to remove ions, unwanted molecules and larger particles from drinking water. In reverse osmosis, an applied pressure is used to overcome osmotic pressure.",
      "image":"./../../../assets/products/3a.jpeg"
    },
    {
      "title":"SANITIZER",
      "description":"Hand sanitizer, also called hand antiseptic, handrub, or hand rub, agent applied to the hands for the purpose of removing common pathogens (disease-causing organisms).",
      "image":"./../../../assets/products/4a.jpeg"
    },
    {
      "title":"ANTI SCALE FOR MEMBRANES",
      "description":"An antiscalant is a pretreatment that gets injected into the feedwater that flows through the RO membrane, preventing the membrane from scaling. RO membrane scaling occurs when particles accumulate on a membrane, causing the membrane's pores to plug.",
      "image":"../../../../assets/products/5a.jpeg"
    },
    {
      "title":"CAUSTIC SODA",
      "description":"Caustic Soda (Sodium hydroxide) is used in many industries: in the manufacture of pulp and paper, textiles, drinking water, soaps and detergents, and as a drain cleaner.",
      "image":"./../../../assets/products/6a.jpeg"
    },
    {
      "title":"RO DRINKING WATER",
      "description":"Reverse osmosis (RO) is a water purification process that uses a partially permeable membrane to remove ions, unwanted molecules and larger particles from drinking water. ... The result is that the solute is retained on the pressurized side of the membrane and the pure solvent is allowed to pass to the other side.",
      "image":"./../../../assets/products/7a.jpg"
    },
    
  ]


  services = [
    {
      "title":"Deminaralised Water",
      "description":"Demineralised water is water that has been purified in such a way that (most of) its mineral- and salt ions are removed. You can think for example of Calcium, Chloride, Sulphate, Magnesium and Sodium. Demineralised water is also known as demi water or deionised water.",
      "link":"/deminaralisedwater",
      "image":"../../../../assets/products/1a.jpeg"
    },
    {
      "title":"Distilled Water",
      "description":"Distilled water is water that has been boiled into vapor and condensed back into liquid in a separate container. Impurities in the original water that do not boil below or near the boiling point of water remain in the original container.",
      "link":"/distilledwater",
      "image":"../../../../assets/products/2a.jpeg"
    },
    {
      "title":"Indistrial RO",
      "description":"Reverse osmosis (RO) is a water purification process that uses a partially permeable membrane to remove ions, unwanted molecules and larger particles from drinking water. In reverse osmosis, an applied pressure is used to overcome osmotic pressure.",
      "link":"/industrialro",
      "image":"../../../../assets/products/3a.jpeg"
    },
    {
      "title":"Sanitizer",
      "description":"Hand sanitizer, also called hand antiseptic, handrub, or hand rub, agent applied to the hands for the purpose of removing common pathogens (disease-causing organisms).",
      "link":"/sanitizer",
      "image":"../../../../assets/products/4a.jpeg"
    },
    {
      "title":"Antiscale for Membrane",
      "description":"An antiscalant is a pretreatment that gets injected into the feedwater that flows through the RO membrane, preventing the membrane from scaling. RO membrane scaling occurs when particles accumulate on a membrane, causing the membrane's pores to plug.",
      "link":"/antiscaleformembrane",
      "image":"../../../../assets/products/5a.jpeg"
    },
    {
      "title":"Caustic Soda",
      "description":"Caustic Soda (Sodium hydroxide) is used in many industries: in the manufacture of pulp and paper, textiles, drinking water, soaps and detergents, and as a drain cleaner.",
      "link":"/causticsoda",
      "image":"../../../../assets/products/6a.jpeg"
    },
    {
      "title":"RO Drinking Water",
      "description":"Reverse osmosis (RO) is a water purification process that uses a partially permeable membrane to remove ions, unwanted molecules and larger particles from drinking water. ... The result is that the solute is retained on the pressurized side of the membrane and the pure solvent is allowed to pass to the other side.",
      "link":"/rofordrinkingwater",
      "image":"../../../../assets/products/7a.jpg"
    },
  ]

  ngOnInit() {

  }

}
